import { Input } from '../common'
import { INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS } from '../../constants/IncidentReportConstants'

const normalize = val => val === 'true'

function IncidentReportAdditionalInformationFields() {
  const radioBlockStyles = {
    marginBottom: '24px',
  }

  return (
    <div className="row">
      <div className="col-xs-12">
        <h3>Additional Information</h3>
        <fieldset>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div style={radioBlockStyles}>
                <p id="lateTestingRequiredLabel">
                  Does the incident require the student(s) to retest?
                </p>
                <Input
                  type="radio"
                  label="Yes"
                  name={SHARED_FIELDS.lateTestingRequiredInd.name}
                  value={true}
                  isRequired={SHARED_FIELDS.lateTestingRequiredInd.required}
                  ariaDescribedBy="lateTestingRequiredLabel"
                  normalize={normalize}
                />
                <Input
                  type="radio"
                  label="No"
                  name={SHARED_FIELDS.lateTestingRequiredInd.name}
                  value={false}
                  isRequired={SHARED_FIELDS.lateTestingRequiredInd.required}
                  ariaDescribedBy="lateTestingRequiredLabel"
                  normalize={normalize}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div style={radioBlockStyles}>
                <p id="altExamOrderedLabel">
                  Has an exam for a subsequent administration been ordered?
                </p>
                <Input
                  type="radio"
                  label="Yes"
                  name={SHARED_FIELDS.altExamOrderedInd.name}
                  value={true}
                  isRequired={SHARED_FIELDS.altExamOrderedInd.required}
                  ariaDescribedBy="altExamOrderedLabel"
                  normalize={normalize}
                />
                <Input
                  type="radio"
                  label="No"
                  name={SHARED_FIELDS.altExamOrderedInd.name}
                  value={false}
                  isRequired={SHARED_FIELDS.altExamOrderedInd.required}
                  ariaDescribedBy="altExamOrderedLabel"
                  normalize={normalize}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  )
}

export default IncidentReportAdditionalInformationFields
