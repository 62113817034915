import { useLocation, useParams } from 'react-router-dom'
import {
  fetchPackingListDetails,
  updateCartons,
  flushPackingListDetails,
} from '../../actions/packingList'
import MaterialDiscrepancies from './MaterialDiscrepancies'
import MaterialTotals from './MaterialTotals'
import PackingPageTitle from './PackingPageTitle'
import PackingListStep2 from './PackingListStep2'
import SubmissionBox from './SubmissionBox'
import AICode from './AICode'
import { Error, Loader, OpenNewWindow } from '../common'
import { queryParamsToObj } from '../../utils/common'
import { getIncidentReportAvailability } from '../../selectors/incidentReports'
import { NAV_LINKS } from '../../constants/NavConstants'

import packingStyles from '../../assets/style/scss/packing-details.scss'

import { STATUS_NOT_SUBMITTED, STATUS_SUBMITTED } from '../../constants/PackingListConstants'

const CARTON_INPUT_FIELD = 'packingCartonInput'

const mapStateToProps = state => {
  const {
    packingListSummary: { packingShipmentsId, packingLists = {} },
    packingListDetails,
    user: {
      data: { isCSR, isSchoolAdmin, selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
  } = state
  const { fetched, fetching, error } = packingListDetails
  const cartonError = packingListDetails?.carton?.error || null
  const { packingListId, testDayType } = packingListDetails
  const { weekNumber = null } = Array.isArray(packingLists[testDayType])
    ? packingLists[testDayType].find(pl => pl.packingListId === packingListId)
    : {}
  const { active } = getIncidentReportAvailability(state)
  return {
    orgId,
    educationPeriodCd,
    disabledForRole: isCSR || isSchoolAdmin,
    fetched,
    fetching,
    error,
    packingShipmentsId,
    packingListDetails: { ...packingListDetails, weekNumber },
    cartonError,
    incidentReportActive: active,
  }
}

const PackingDetails = ({
  orgId,
  educationPeriodCd,
  disabledForRole,
  fetched,
  fetching,
  error,
  packingShipmentsId,
  packingListDetails: {
    packingListId,
    testDayType,
    examType,
    status,
    noOfCartonsReturned,
    weekNumber,
  },
  cartonError,
  incidentReportActive,
  fetchPackingListDetails,
  updateCartons,
  flushPackingListDetails,
}) => {
  const { search } = useLocation()
  const print = !!queryParamsToObj(search).print || false
  const { packingListId: packingListIdFromUrl = '' } = useParams()
  const [cartonValue, setCartonValue] = useState('')
  const [entryError, setEntryError] = useState(false)

  useEffect(() => {
    if (orgId && educationPeriodCd && packingShipmentsId && packingListIdFromUrl) {
      fetchPackingListDetails({
        orgId,
        educationPeriodCd,
        packingShipmentsId,
        packingListId: packingListIdFromUrl,
      })
    }
    return () => flushPackingListDetails()
  }, [orgId, educationPeriodCd, packingShipmentsId, packingListIdFromUrl])

  useEffect(() => {
    if (fetched && cartonValue !== noOfCartonsReturned && noOfCartonsReturned !== null) {
      setCartonValue(noOfCartonsReturned)
    }
  }, [fetched, noOfCartonsReturned])

  useEffect(() => {
    if (print && fetched) {
      window.print()
    }
  }, [print, fetched])

  useEffect(() => {
    if (
      orgId &&
      educationPeriodCd &&
      packingShipmentsId &&
      packingListId &&
      parseInt(cartonValue, 10) !== noOfCartonsReturned &&
      Number(cartonValue) > 0
    ) {
      const sendPackage = { packingListId, noOfCartonsReturned: parseInt(cartonValue, 10) }
      updateCartons({ orgId, educationPeriodCd, sendPackage, packingShipmentsId, packingListId })
    }
  }, [
    orgId,
    educationPeriodCd,
    cartonValue,
    packingShipmentsId,
    packingListId,
    noOfCartonsReturned,
  ])

  const sendUpdateState = e => {
    e.preventDefault()
    if (
      !String(e.target.value).match(/\D/gm) &&
      (Number(e.target.value) > 0 || e.target.value === '')
    ) {
      setCartonValue(e.target.value)
      setEntryError(false)
    } else {
      setEntryError(true)
    }
  }

  if (error) {
    return <Error message={error} />
  }

  if (fetching) {
    return <Loader />
  }

  return (
    <div className="container">
      <div className={!print ? 'col-sm-8 col-md-9' : 'col-xs-12'}>
        <div style={{ display: 'flex' }}>
          <PackingPageTitle pkg={{ testDayType, examType, weekNumber }} />
          <AICode print={print} />
        </div>

        {!print ? (
          <>
            {incidentReportActive ? (
              <div style={{ marginBottom: '16px' }}>
                <OpenNewWindow url={NAV_LINKS.incidentReportHistory}>
                  Incident Report Submission History
                </OpenNewWindow>
              </div>
            ) : null}
            <p>Follow the steps below to complete your packing list</p>
          </>
        ) : null}
        <h4>Step 1 of 2: Answer Shipment Question</h4>
        {!print ? (
          <form onSubmit={e => e.preventDefault()}>
            <div className="form-group" id={`${packingStyles.upperLeft}`}>
              <label htmlFor={CARTON_INPUT_FIELD}>
                <span style={{ marginBottom: 0 }}>
                  How many cartons are being returned with this shipment?
                </span>
                {cartonError && <Error title="Error Updating Cartons" message={cartonError} />}
                <input
                  type="text"
                  name={CARTON_INPUT_FIELD}
                  id={CARTON_INPUT_FIELD}
                  role="spinbutton"
                  aria-valuemax={999}
                  aria-valuemin={1}
                  aria-valuenow={cartonValue}
                  aria-label="Number of cartons"
                  minLength={1}
                  maxLength={3}
                  value={cartonValue}
                  onChange={e => sendUpdateState(e)}
                  className="form-control"
                  style={{ width: '4em', textAlign: 'center' }}
                  disabled={status === STATUS_SUBMITTED || disabledForRole}
                />
                {entryError && (
                  <p className="cb-error-msg" role="alert" tabIndex="-1" aria-live="polite">
                    Error: Please enter a positive whole number
                  </p>
                )}
              </label>
            </div>
          </form>
        ) : (
          <div>
            <p style={{ marginBottom: '.5em' }}>
              Number of cartons being returned with this shipment:
            </p>
            <div style={{ fontSize: '2em', fontWeight: 'bold' }}>{noOfCartonsReturned}</div>
          </div>
        )}
        <div style={{ marginTop: '24px' }}>
          <PackingListStep2 packingListId={packingListId} forPrinting={print} />
        </div>
        <div className="row">
          <div className="col-md-12">
            <MaterialDiscrepancies />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <MaterialTotals />
          </div>
        </div>
      </div>
      {!print && (
        <div className="col-sm-4 col-md-3">
          <SubmissionBox
            status={status || STATUS_NOT_SUBMITTED}
            cartonLock={Boolean(cartonValue)}
          />
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps, {
  fetchPackingListDetails,
  updateCartons,
  flushPackingListDetails,
})(PackingDetails)
