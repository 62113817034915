import { iam } from '@myap/ui-library/esm/profile'
import { SubmissionError } from 'redux-form'
import * as c from '../constants/IncidentReportConstants'
import axios, { getServerErrorMessage } from '../utils/axios'

const { getJWTToken, getAuthSession } = iam()

export const fetchIncidentReportHistory =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    dispatch({ type: c.FETCH_INCIDENT_REPORT_HISTORY_PENDING })

    try {
      const config = {
        headers: {
          'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
          'X-CB-Catapult-Authorization-Token': getJWTToken(),
        },
      }
      const { data } = await axios.get(
        `${Config.API_GATE_URL}/incident-reports/organizations/${orgId}/education-periods/${educationPeriodCd}/reports`,
        config
      )
      dispatch({
        type: c.FETCH_INCIDENT_REPORT_HISTORY_FULFILLED,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: c.FETCH_INCIDENT_REPORT_HISTORY_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const fetchDraftIncidentReport =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    dispatch({ type: c.FETCH_INCIDENT_REPORT_DRAFT_PENDING })

    try {
      const config = {
        headers: {
          'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
          'X-CB-Catapult-Authorization-Token': getJWTToken(),
        },
      }
      const { data } = await axios.get(
        `${Config.API_GATE_URL}/incident-reports/organizations/${orgId}/education-periods/${educationPeriodCd}/pending`,
        config
      )
      dispatch({
        type: c.FETCH_INCIDENT_REPORT_DRAFT_FULFILLED,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: c.FETCH_INCIDENT_REPORT_DRAFT_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const submitIncidentReport =
  ({ orgId, educationPeriodCd, values }) =>
  async dispatch => {
    dispatch({ type: c.SUBMIT_INCIDENT_REPORT_PENDING })

    try {
      const config = {
        headers: {
          'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
          'X-CB-Catapult-Authorization-Token': getJWTToken(),
        },
      }
      await axios.post(
        `${Config.API_GATE_URL}/incident-reports/organizations/${orgId}/education-periods/${educationPeriodCd}/reports`,
        values,
        config
      )
      dispatch({ type: c.SUBMIT_INCIDENT_REPORT_FULFILLED })
    } catch (err) {
      dispatch({ type: c.SUBMIT_INCIDENT_REPORT_REJECTED, payload: getServerErrorMessage(err) })
      throw new SubmissionError({
        _error: `Error submitting incident report: ${getServerErrorMessage(err)}`,
      })
    }
  }

export const resetIncidentReport = () => dispatch => dispatch({ type: c.RESET_INCIDENT_REPORT })

export const resetDraftIncidentReport = () => dispatch => dispatch({ type: c.RESET_DRAFT_INCIDENT_REPORT })
