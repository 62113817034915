import { change } from 'redux-form'
import {
  Input,
  InlineText,
  RequiredValidation,
  PositiveNumberValidation,
  PositiveMaximumNumberValidation,
} from '../common'
import {
  INCIDENT_REPORT_FORM_NAME,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES as DIGITAL_FIELDS,
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
} from '../../constants/IncidentReportConstants'

const IncidentReportDigitalIncidentFields = ({ currentValues, change }) => {
  const fieldsetStyles = {
    marginTop: 0,
  }
  const childControlStyles = {
    marginLeft: '26px',
  }
  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          <div style={{ marginBottom: '24px' }}>
            <fieldset style={fieldsetStyles}>
              <legend style={{ fontWeight: 'bold' }}>
                Student Technology Issue (Select all that apply)
              </legend>
              <Input
                type="checkbox"
                label="Battery died"
                name={DIGITAL_FIELDS.batteryDiedInd.name}
                isRequired={DIGITAL_FIELDS.batteryDiedInd.required}
              />
              <Input
                type="checkbox"
                label="Connection dropped"
                name={DIGITAL_FIELDS.connectionDroppedInd.name}
                isRequired={DIGITAL_FIELDS.connectionDroppedInd.required}
              />
              <Input
                type="checkbox"
                label="Device froze or crashed"
                name={DIGITAL_FIELDS.deviceFrozeOrCrashInd.name}
                isRequired={DIGITAL_FIELDS.deviceFrozeOrCrashInd.required}
              />
              <Input
                type="checkbox"
                label={<>Student device didn&#39;t meet requirements</>}
                name={DIGITAL_FIELDS.deviceDidNotMeetReqInd.name}
                isRequired={DIGITAL_FIELDS.deviceDidNotMeetReqInd.required}
              />
              <Input
                type="checkbox"
                label="Loaner device unavailable or not working"
                name={DIGITAL_FIELDS.loanerDeviceIssueInd.name}
                isRequired={DIGITAL_FIELDS.loanerDeviceIssueInd.required}
              />
              <Input
                type="checkbox"
                label="Another testing application was installed instead of Bluebook"
                name={DIGITAL_FIELDS.anotherTestAppInstalledInd.name}
                isRequired={DIGITAL_FIELDS.anotherTestAppInstalledInd.required}
              />
              <Input
                type="checkbox"
                label={<>Bluebook was not installed on the student&#39;s device</>}
                name={DIGITAL_FIELDS.blueBookNotInstalledInd.name}
                isRequired={DIGITAL_FIELDS.blueBookNotInstalledInd.required}
              />
              <Input
                type="checkbox"
                label={<>Student didn&#39;t complete exam setup before arrival</>}
                name={DIGITAL_FIELDS.examSetUpNotCompletedInd.name}
                isRequired={DIGITAL_FIELDS.examSetUpNotCompletedInd.required}
              />
              <Input
                type="checkbox"
                label={<>Student didn&#39;t have admission ticket</>}
                name={DIGITAL_FIELDS.noAdmissionTicketInd.name}
                isRequired={DIGITAL_FIELDS.noAdmissionTicketInd.required}
              />
              <Input
                type="checkbox"
                label="Other"
                name={DIGITAL_FIELDS.studentTechIssueOther.name}
                isRequired={DIGITAL_FIELDS.studentTechIssueOther.required}
              />
            </fieldset>
          </div>
          <div style={{ marginBottom: '24px' }}>
            <fieldset style={fieldsetStyles}>
              <legend style={{ fontWeight: 'bold' }}>
                Bluebook application problem (Select all that apply)
              </legend>
              <Input
                type="checkbox"
                label={<>Student couldn&#39;t open Bluebook</>}
                name={DIGITAL_FIELDS.couldNotOpenInd.name}
                isRequired={DIGITAL_FIELDS.couldNotOpenInd.required}
              />
              <Input
                type="checkbox"
                label={<>Student couldn&#39;t sign in</>}
                name={DIGITAL_FIELDS.couldNotSignInd.name}
                isRequired={DIGITAL_FIELDS.couldNotSignInd.required}
              />
              <Input
                type="checkbox"
                label={<>The test was not available on the student&#39;s Bluebook homepage</>}
                name={DIGITAL_FIELDS.testNotAvailableInd.name}
                isRequired={DIGITAL_FIELDS.testNotAvailableInd.required}
              />
              <Input
                type="checkbox"
                label={<>Student couldn&#39;t start the test after entering the start code</>}
                name={DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name}
                isRequired={DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.required}
              />
              <Input
                type="checkbox"
                label="Inaccurate timing"
                name={DIGITAL_FIELDS.inAccurateTimingInd.name}
                isRequired={DIGITAL_FIELDS.inAccurateTimingInd.required}
              />
              <Input
                type="checkbox"
                label={<>Content didn&#39;t display or was hard to read</>}
                name={DIGITAL_FIELDS.contentDisplayIssueInd.name}
                isRequired={DIGITAL_FIELDS.contentDisplayIssueInd.required}
              />
              <Input
                type="checkbox"
                label={<>Embedded calculator, countdown clock, or other tools didn&#39;t work</>}
                name={DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name}
                isRequired={DIGITAL_FIELDS.calculatorClockOrToolIssueInd.required}
              />
              <Input
                type="checkbox"
                label="Answers were not submitted"
                name={DIGITAL_FIELDS.answerNotSubmittedInd.name}
                isRequired={DIGITAL_FIELDS.answerNotSubmittedInd.required}
              />
              <Input
                type="checkbox"
                label="Other"
                name={DIGITAL_FIELDS.blueBookProblemOther.name}
                isRequired={DIGITAL_FIELDS.blueBookProblemOther.required}
              />
            </fieldset>
          </div>
          <div style={{ marginBottom: '24px' }}>
            <fieldset style={fieldsetStyles}>
              <legend style={{ fontWeight: 'bold' }}>
                Staff Technology Issue (Select all that apply)
              </legend>
              <Input
                type="checkbox"
                label="Battery died"
                name={DIGITAL_FIELDS.staffBatteryDiedInd.name}
                isRequired={DIGITAL_FIELDS.staffBatteryDiedInd.required}
              />
              <Input
                type="checkbox"
                label="Connection dropped"
                name={DIGITAL_FIELDS.staffConnectionDroppedInd.name}
                isRequired={DIGITAL_FIELDS.staffConnectionDroppedInd.required}
              />
              <Input
                type="checkbox"
                label="Device froze or crashed"
                name={DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.name}
                isRequired={DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.required}
              />
              <Input
                type="checkbox"
                label="Staff device issue"
                name={DIGITAL_FIELDS.staffDeviceIssueInd.name}
                isRequired={DIGITAL_FIELDS.staffDeviceIssueInd.required}
              />
              <Input
                type="checkbox"
                label="Other"
                name={DIGITAL_FIELDS.staffTechIssueOther.name}
                isRequired={DIGITAL_FIELDS.staffTechIssueOther.required}
              />
            </fieldset>
          </div>
        </div>
        <div className="col-sm-6 col-xs-12">
          <div style={{ marginBottom: '24px' }}>
            <fieldset style={fieldsetStyles}>
              <legend style={{ fontWeight: 'bold' }}>
                Test Day Toolkit problem (Select all that apply)
              </legend>
              <Input
                type="checkbox"
                label={<>Couldn&#39;t access Test Day Toolkit</>}
                name={DIGITAL_FIELDS.couldNotAccessTDTKInd.name}
                isRequired={DIGITAL_FIELDS.couldNotAccessTDTKInd.required}
              />
              <Input
                type="checkbox"
                label="Inaccurate student roster data"
                name={DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name}
                isRequired={DIGITAL_FIELDS.inAccurateStudentRosterDataInd.required}
              />
              <Input
                type="checkbox"
                label={<>Couldn&#39;t check students in</>}
                name={DIGITAL_FIELDS.studentCheckInIssueInd.name}
                isRequired={DIGITAL_FIELDS.studentCheckInIssueInd.required}
              />
              <Input
                type="checkbox"
                label="Inaccurate student statuses in dashboard"
                name={DIGITAL_FIELDS.inAccurateStudentStatusInd.name}
                isRequired={DIGITAL_FIELDS.inAccurateStudentStatusInd.required}
              />
              <Input
                type="checkbox"
                label="Other"
                name={DIGITAL_FIELDS.testDayToolKitProblemOther.name}
                isRequired={DIGITAL_FIELDS.testDayToolKitProblemOther.required}
              />
            </fieldset>
          </div>
          <div style={{ marginBottom: '24px' }}>
            <fieldset style={fieldsetStyles}>
              <legend style={{ fontWeight: 'bold' }}>
                Facility Technology Issue (Select all that apply)
              </legend>
              <Input
                type="checkbox"
                label="Network outage"
                name={DIGITAL_FIELDS.networkOutageInd.name}
                isRequired={DIGITAL_FIELDS.networkOutageInd.required}
              />
              <Input
                type="checkbox"
                label="Power outage"
                name={DIGITAL_FIELDS.powerOutageInd.name}
                isRequired={DIGITAL_FIELDS.powerOutageInd.required}
              />
              <Input
                type="checkbox"
                label="Other"
                name={DIGITAL_FIELDS.facilityTechIssueOther.name}
                isRequired={DIGITAL_FIELDS.facilityTechIssueOther.required}
              />
            </fieldset>
          </div>
          <Input
            type="checkbox"
            label="Illness"
            name={SHARED_FIELDS.illnessInd.name}
            isRequired={SHARED_FIELDS.illnessInd.required}
          />
          <div style={{ marginBottom: '24px' }}>
            <fieldset style={fieldsetStyles}>
              <legend className="sr-only">Rule Violation (Select any that apply)</legend>
              <Input
                type="checkbox"
                label="Rule Violation (Select any that apply)"
                input={{
                  name: SHARED_FIELDS.misconductInd.name,
                  checked: currentValues[SHARED_FIELDS.misconductInd.name] ?? false,
                  onChange: e => {
                    const { checked } = e.target
                    change(INCIDENT_REPORT_FORM_NAME, SHARED_FIELDS.misconductInd.name, checked)
                    if (!checked) {
                      change(
                        INCIDENT_REPORT_FORM_NAME,
                        SHARED_FIELDS.studentDismissedInd.name,
                        false
                      )
                      change(
                        INCIDENT_REPORT_FORM_NAME,
                        SHARED_FIELDS.usedProhibitedDeviceInd.name,
                        false
                      )
                      change(
                        INCIDENT_REPORT_FORM_NAME,
                        SHARED_FIELDS.removeExamMaterialsInd.name,
                        false
                      )
                    }
                  },
                }}
                name={SHARED_FIELDS.misconductInd.name}
                isRequired={SHARED_FIELDS.misconductInd.required}
              />
              <Input
                type="checkbox"
                label="Student Dismissed"
                name={SHARED_FIELDS.studentDismissedInd.name}
                isRequired={SHARED_FIELDS.studentDismissedInd.required}
                disabled={!currentValues[SHARED_FIELDS.misconductInd.name]}
                style={childControlStyles}
              />
              <Input
                type="checkbox"
                label="Student Observed with a Prohibited Device"
                name={SHARED_FIELDS.usedProhibitedDeviceInd.name}
                isRequired={SHARED_FIELDS.usedProhibitedDeviceInd.required}
                disabled={!currentValues[SHARED_FIELDS.misconductInd.name]}
                style={childControlStyles}
              />
              <Input
                type="checkbox"
                label="Student Removed/Attempted to Remove Exam Materials"
                name={SHARED_FIELDS.removeExamMaterialsInd.name}
                isRequired={SHARED_FIELDS.removeExamMaterialsInd.required}
                disabled={!currentValues[SHARED_FIELDS.misconductInd.name]}
                style={{ ...childControlStyles, marginBottom: '24px' }}
              />
            </fieldset>
          </div>
          <InlineText
            name={SHARED_FIELDS.disturbanceMinutes.name}
            validate={
              currentValues[SHARED_FIELDS.disturbanceMinutesInd.name]
                ? [RequiredValidation, PositiveNumberValidation, PositiveMaximumNumberValidation]
                : null
            }
            label=""
            placeholder="Enter number of minutes of disturbance"
            controlInput={{
              type: 'checkbox',
              name: SHARED_FIELDS.disturbanceMinutesInd.name,
              label: 'Disturbance-Number of Minutes',
              input: {
                onChange: e => {
                  const { checked } = e.target
                  change(
                    INCIDENT_REPORT_FORM_NAME,
                    SHARED_FIELDS.disturbanceMinutesInd.name,
                    checked
                  )
                  if (!checked) {
                    change(INCIDENT_REPORT_FORM_NAME, SHARED_FIELDS.disturbanceMinutes.name, null)
                  } else {
                    setTimeout(
                      () => document.getElementById(SHARED_FIELDS.disturbanceMinutes.name).focus(),
                      50
                    )
                  }
                },
              },
            }}
            disabled={!currentValues[SHARED_FIELDS.disturbanceMinutesInd.name]}
            isRequired={currentValues[SHARED_FIELDS.disturbanceMinutesInd.name]}
            maxlength={4}
            withFlexbox={false}
            fullWidthError={true}
            textboxGroupStyles={childControlStyles}
          />
          <InlineText
            name={SHARED_FIELDS.interruptionMinutes.name}
            validate={
              currentValues[SHARED_FIELDS.interruptionMinutesInd.name]
                ? [RequiredValidation, PositiveNumberValidation, PositiveMaximumNumberValidation]
                : null
            }
            label=""
            placeholder="Enter number of minutes of interruption"
            controlInput={{
              type: 'checkbox',
              name: SHARED_FIELDS.interruptionMinutesInd.name,
              label: 'Interruption-Number of Minutes',
              input: {
                onChange: e => {
                  const { checked } = e.target
                  change(
                    INCIDENT_REPORT_FORM_NAME,
                    SHARED_FIELDS.interruptionMinutesInd.name,
                    checked
                  )
                  if (!checked) {
                    change(INCIDENT_REPORT_FORM_NAME, SHARED_FIELDS.interruptionMinutes.name, null)
                  } else {
                    setTimeout(
                      () => document.getElementById(SHARED_FIELDS.interruptionMinutes.name).focus(),
                      50
                    )
                  }
                },
              },
            }}
            disabled={!currentValues[SHARED_FIELDS.interruptionMinutesInd.name]}
            isRequired={currentValues[SHARED_FIELDS.interruptionMinutesInd.name]}
            maxlength={4}
            withFlexbox={false}
            fullWidthError={true}
            textboxGroupStyles={childControlStyles}
          />
          <Input
            type="checkbox"
            label="Other"
            name={SHARED_FIELDS.otherInd.name}
            isRequired={SHARED_FIELDS.otherInd.required}
          />
        </div>
      </div>
    </>
  )
}

export default connect(state => state, { change })(IncidentReportDigitalIncidentFields)
