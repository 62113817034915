import { isValid, isDirty, submit } from 'redux-form'
import deepEqual from 'deep-equal'
import { Modal } from '../../components/common'
import { isEmpty } from '../../utils/common'
import { openModal } from '../../actions/app'
import {
  SELECT_SPECIAL_DIGITAL_FORMATS_FORM,
  UPDATE_TYPE_DIGITAL_ACCOMMODATIONS,
  SDF_MULTIDAY,
} from '../../constants/StudentConstants'
import { DIGITAL_EXAM } from '../../constants/SettingsConstants'
import SelectSpecialDigitalFormatsToBeOrderedForm from '../forms/SelectSpecialDigitalFormatsToBeOrderedForm'
import { getOrderDeadlineIsPast } from '../../selectors/section'
import { resetStudentUpdate } from '../../actions/studentsCommon'

const mapStateToProps = (state, { examId }) => {
  const {
    status: {
      data: { isSubmitted },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsDeadlines: { data: settingsDeadlinesData },
    studentsByOrg: {
      update: { type, updating, updated, error, id },
      courseMap,
      studentMap,
      sectionMap,
      exams,
    },
  } = state
  const deadlinesData = settingsDeadlinesData?.[selectedEducationPeriod] ?? {}
  const exam = exams.find(e => e.examId === examId)
  const { lastName, firstName } = studentMap?.[exam.studentId] ?? {}
  const { specialDigitalFormats = {} } = exam

  return {
    isDigital: exam.examFormat === DIGITAL_EXAM,
    updating,
    updated,
    error,
    valid: isValid(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)(state),
    dirty: isDirty(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)(state),
    exam,
    courseName: courseMap[exam.testCd].name,
    studentName: `${firstName} ${lastName}`,
    isChangeOrder: isSubmitted || getOrderDeadlineIsPast(sectionMap[exam.sectionId], deadlinesData),
    hasMultidayTesting: specialDigitalFormats[SDF_MULTIDAY] !== undefined,
  }
}

function footerActions({ valid, updating, dirty, submitForm }) {
  return [
    {
      buttonLabel: 'Cancel',
      isDismissable: true,
      isPrimary: false,
      isDisabled: updating,
    },
    {
      buttonLabel: 'Update',
      isPrimary: true,
      onClick: submitForm,
      isDisabled: !valid || updating || !dirty,
      busy: updating,
    },
  ]
}

const OrderSpecialDigitalFormatsModal = ({
  isDigital,
  updating,
  updated,
  error,
  valid,
  dirty,
  exam,
  courseName,
  studentName,
  isChangeOrder,
  hasMultidayTesting,
  modalCloseFocusElem,
  submit,
  resetStudentUpdate,
  openModal,
}) => {
  const [shouldCloseModal, setShouldCloseModal] = useState(false)
  const prevSpecialDigitalFormats = useRef()
  useEffect(() => {
    prevSpecialDigitalFormats.current = exam?.specialDigitalFormats
  })

  useEffect(() => {
    if (updated) {
      setShouldCloseModal(true)
    }
    return () => resetStudentUpdate()
  }, [updated])

  const onCloseAction = () => {
    // Show change order modal if exam.digitalAccommodations have changed
    if (
      isChangeOrder &&
      !isEmpty(exam?.specialDigitalFormats) &&
      !deepEqual(prevSpecialDigitalFormats.current, exam?.specialDigitalFormats, { strict: true })
    ) {
      openModal('UpdateStudentExamSuccessModal', {
        exam,
        type: UPDATE_TYPE_DIGITAL_ACCOMMODATIONS,
        courseName,
        studentName,
        modalCloseFocusElem,
      })
    }
  }

  return (
    <Modal
      shouldCloseModal={shouldCloseModal}
      onCloseAction={onCloseAction}
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle={`${courseName} ${
        isDigital ? 'Digital' : 'Hybrid'
      } Exam: Special Format for Students with Accommodations`}
      footerActions={footerActions({
        valid,
        updating,
        dirty,
        submitForm: () => submit(SELECT_SPECIAL_DIGITAL_FORMATS_FORM),
      })}
    >
      {isDigital ? (
        <>
          <p>
            This student will receive a format of the digital exam with the accommodations listed
            below. Confirm that the accommodations listed are accurate for this exam. If the student
            wants to waive any of these accommodations for this digital exam, you&#39;ll need to
            de-select those accommodations here and then click <strong>Update</strong> at the bottom
            of the screen.
          </p>
          <p>
            If the accommodations listed below are accurate and the student doesn&#39;t want to make
            any changes, no further action is needed.
          </p>
          <p>
            <strong>Note:</strong> As with paper-and-pencil exams, some types of accommodations
            require students to take the digital exam in separate rooms from other test-takers.
            Refer to the <em>AP Accommodations Guide</em> for details about accommodations that
            require separate testing rooms.
          </p>
        </>
      ) : (
        <>
          <p>
            This student will receive a format of the hybrid exam with the accommodations listed
            below. Confirm that the accommodations listed are accurate for this exam. If the student
            wants to waive any of these accommodations for this exam, you&#39;ll need to de-select
            those accommodations here and then click <strong>Update</strong> at the bottom of the
            screen.
          </p>
          <p>
            If the accommodations listed below are accurate and the student doesn&#39;t want to make
            any changes, no further action is needed.
          </p>
          <p>
            <strong>Note:</strong> As with paper-and-pencil exams, some types of accommodations
            require students to take the exam in separate rooms from other test-takers. Refer to the{' '}
            <em>AP Accommodations Guide</em> for details about accommodations that require separate
            testing rooms.
          </p>
        </>
      )}
      <SelectSpecialDigitalFormatsToBeOrderedForm exam={exam} />
    </Modal>
  )
}

export default connect(mapStateToProps, {
  submit,
  openModal,
  resetStudentUpdate,
})(OrderSpecialDigitalFormatsModal)
