import { Link } from 'react-router-dom'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import {
  fetchExceptionTestingApprovalRequestDetail,
  fetchOrdersAfterDeadlineApprovalRequestDetail,
} from '../../../actions/approvalRequests'
import { TableTemplate } from '.'
import { DisplayExamDate, DotSizeStatusLabel, Loader } from '../../common'
import { APPROVAL_PENDING, APPROVAL_REJECTED } from '../../../constants/OrderConstants'
import { getExamWindowRefData } from '../../../selectors/examWindows'
import {
  getExceptionTestingApprovalDetails,
  getOrdersAfterDeadlineApprovalDetails,
} from '../../../selectors/order'

import styles from '../../../assets/style/scss/table-utils.scss'

const mapStateToProps = state => ({ examWindowRefData: getExamWindowRefData(state) })

const ApprovedFor = ({ data }) => {
  const { etsApprovalStatus, items = [], quantityOrdered = null, description = '' } = data

  if (etsApprovalStatus === APPROVAL_REJECTED) {
    if (items.length) {
      return (
        <>
          <div>Approved for:</div>
          {items.map(item => (
            <div key={APRICOT.utils.uniqueID()}>
              {item.quantityOrdered} {item.description}
            </div>
          ))}
        </>
      )
    }
    if (quantityOrdered) {
      return (
        <div>
          Approved for: {quantityOrdered} {description}
        </div>
      )
    }
    return null
  }
  return null
}

const ApprovalStatusColumn = ({ data }) => {
  const { etsApprovalStatus, updateTimestamp, comments } = data
  return (
    <td>
      <DotSizeStatusLabel status={etsApprovalStatus} />
      {etsApprovalStatus !== APPROVAL_PENDING && (
        <div>{formatDate(updateTimestamp, DATETIME_FORMATS.longMonthDayYear)}</div>
      )}
      {comments && <div style={{ fontStyle: 'italic' }}>&quot;{comments}&quot;</div>}
      <ApprovedFor data={data} />
    </td>
  )
}

const specialExamRequestsColumns = id => [
  {
    headerText: { title: 'Student Name' },
    key: 'studentName',
    sorting: 'asc',
    headerAttributes: { id: `${id}-StudentName` },
    defaultSort: true,
  },
  {
    headerText: { title: 'Course' },
    key: 'course',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Course` },
  },
  {
    headerText: { title: 'Exam Date' },
    key: 'examDate',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ExamDate` },
  },
  {
    headerText: { title: 'Approval Status' },
    key: 'approvalStatusSortOrder',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ApprovalStatus` },
  },
]

const renderSpecialExamRequestsRow = data =>
  data.map(c => (
    <tr key={c.specialOrderId}>
      <th scope="row">
        <Link to={`/student/${c.studentId}`}>{c.studentName}</Link>
      </th>
      <td>{c.course}</td>
      <td className={styles['with-icon']}>
        <div className={`${styles['exam-date-wrapper']} ${styles.flexible}`}>
          <DisplayExamDate course={c} />
        </div>
      </td>
      <ApprovalStatusColumn data={c} />
    </tr>
  ))

const secureMaterialsColumns = id => [
  {
    headerText: { title: 'Course' },
    key: 'course',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Course` },
  },
  {
    headerText: { title: 'Exam Date' },
    key: ['examDate', 'course'],
    sorting: 'asc',
    headerAttributes: { id: `${id}-ExamDate` },
    defaultSort: true,
  },
  {
    headerText: { title: 'Materials Request' },
    key: 'items',
    headerAttributes: { id: `${id}-Material` },
  },
  {
    headerText: { title: 'Approval Status' },
    key: 'approvalStatusSortOrder',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ApprovalStatus` },
  },
]

const renderSecureMaterialsRequestsRow = data =>
  data.map(m => (
    <tr key={m.specialOrderId}>
      <th scope="row">{m.course}</th>
      <td className={styles['with-icon']}>
        <div className={`${styles['exam-date-wrapper']} ${styles.flexible}`}>
          <DisplayExamDate course={m} />
        </div>
      </td>
      <td>
        {m.items.map(item => (
          <div key={APRICOT.utils.uniqueID()}>
            {item.quantity} {item.description}
          </div>
        ))}
      </td>
      <ApprovalStatusColumn data={m} />
    </tr>
  ))

const nonSecureMaterialsColumns = id => [
  {
    headerText: { title: 'Materials Request' },
    key: 'description',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Name` },
  },
  {
    headerText: { title: 'Exam Date' },
    key: ['examDateType'],
    sorting: 'asc',
    headerAttributes: { id: `${id}-ExamDate` },
    defaultSort: true,
  },
  {
    headerText: { title: 'Quantity' },
    key: 'quantity',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Quantity` },
  },
  {
    headerText: { title: 'Approval Status' },
    key: 'approvalStatusSortOrder',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ApprovalStatus` },
  },
]

const renderNonSecureMaterialsRequestsRow = (data, examWindowRefData) =>
  data.map(m => (
    <tr key={m.specialOrderId}>
      <th scope="row">{m.description}</th>
      <td>{examWindowRefData[m.examDateType]?.displayName}</td>
      <td>{m.quantity}</td>
      <ApprovalStatusColumn data={m} />
    </tr>
  ))

const exceptionTestingColumns = id => [
  {
    headerText: { title: 'Course' },
    key: 'course',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Course` },
    defaultSort: true,
  },
  {
    headerText: { title: 'Quantity' },
    key: ['totalExams', 'course'],
    sorting: 'asc',
    headerAttributes: { id: `${id}-Quantity` },
  },
  {
    headerText: { title: 'Approval Status' },
    key: 'approvalStatus',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ApprovalStatus` },
  },
]

const StudentList = ({ open, clickAction, students = [], loading = false, error }) => (
  <>
    <div>
      <button type="button" className="btn-link cb-no-padding" onClick={clickAction}>
        {open ? 'Collapse' : 'Expand to see'} students{' '}
        <i className={`cb-glyph cb-${open ? 'minus' : 'plus'}`} />
      </button>{' '}
      {loading ? <Loader size="sm" style={{ display: 'inline-block' }} /> : null}
    </div>
    {!loading ? (
      <div style={open ? { display: 'block' } : { display: 'none' }}>
        {error ? (
          <p className="cb-error-msg" aria-live="polite">
            This request has been updated. To view the latest student list please submit any
            outstanding orders.
          </p>
        ) : (
          <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
            {students.map(({ studentLastName, studentFirstName }, i) => (
              <li key={i}>
                {studentLastName}, {studentFirstName}
              </li>
            ))}
          </ul>
        )}
      </div>
    ) : null}
  </>
)

const ExceptionTestingStudentsList = connect(
  (state, { id }) => ({ details: getExceptionTestingApprovalDetails(state, id) }),
  {
    fetchExceptionTestingApprovalRequestDetail,
  }
)(({ details, id, status, fetchExceptionTestingApprovalRequestDetail }) => {
  const { fetching, fetched, error, data } = details
  const [open, toggleOpen] = useState(false)

  const clickAction = () => toggleOpen(!open)

  useEffect(() => {
    if (status === APPROVAL_PENDING && open && !fetching && !fetched && !error) {
      fetchExceptionTestingApprovalRequestDetail(id)
    }
  }, [open, fetching, fetched, status, error])

  if (status === APPROVAL_PENDING) {
    return (
      <StudentList
        open={open}
        clickAction={clickAction}
        students={data?.examsNotStarted}
        loading={fetching}
        error={error}
      />
    )
  }

  return null
})

const renderExceptionTestingRequestsRow = data =>
  data.map(e => (
    <tr key={e.specialOrderId}>
      <th scope="row">{e.course}</th>
      <td>
        <div>{e.totalExams}</div>
        <ExceptionTestingStudentsList id={e.specialOrderId} status={e.etsApprovalStatus} />
      </td>
      <ApprovalStatusColumn data={e} />
    </tr>
  ))

const ordersAfterDeadlineRequestsColumns = id => [
  {
    headerText: { title: 'Course' },
    key: 'course',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Course` },
    defaultSort: true,
  },
  {
    headerText: { title: 'Exam Date' },
    key: 'examDate',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ExamDate` },
  },
  {
    headerText: { title: 'Quantity' },
    key: ['totalExams', 'course'],
    sorting: 'asc',
    headerAttributes: { id: `${id}-Quantity` },
  },
  {
    headerText: { title: 'Approval Status' },
    key: 'approvalStatusSortOrder',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ApprovalStatus` },
  },
]

const OrdersAfterDeadlineStudentsList = connect(
  (state, { id }) => ({ details: getOrdersAfterDeadlineApprovalDetails(state, id) }),
  {
    fetchOrdersAfterDeadlineApprovalRequestDetail,
  }
)(({ details, id, status, fetchOrdersAfterDeadlineApprovalRequestDetail }) => {
  const { fetching, fetched, error, data } = details
  const [open, toggleOpen] = useState(false)

  const clickAction = () => toggleOpen(!open)

  useEffect(() => {
    if (status === APPROVAL_PENDING && open && !fetching && !fetched && !error) {
      fetchOrdersAfterDeadlineApprovalRequestDetail(id)
    }
  }, [open, fetching, fetched, status, error])

  if (status === APPROVAL_PENDING) {
    return (
      <StudentList
        open={open}
        clickAction={clickAction}
        students={data?.students}
        loading={fetching}
        error={error}
      />
    )
  }

  return null
})

const renderOrdersAfterDeadlineRequestsRow = data =>
  data.map(c => (
    <tr key={c.specialOrderId}>
      <th scope="row">{c.course}</th>
      <td className={styles['with-icon']}>
        <div className={`${styles['exam-date-wrapper']} ${styles.flexible}`}>
          <DisplayExamDate course={c} />
        </div>
      </td>
      <td>
        <div>{c.totalExams}</div>
        <OrdersAfterDeadlineStudentsList id={c.specialOrderId} status={c.etsApprovalStatus} />
      </td>
      <ApprovalStatusColumn data={c} />
    </tr>
  ))

export const SpecialExamsRequests = ({ data = [], id, error }) => (
  <TableTemplate
    header="Special Exam Materials"
    headerStyle={{ marginTop: 30, marginBottom: 5 }}
    emptyText="You do not have any special exam materials requests or all requests have been hidden by the selected filter."
    error={error}
    tableAttrs={{
      id,
      caption: 'View all special exam material requests',
      summary:
        'Exam adjustments to your order are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers.',
      data,
      rowBuilder: renderSpecialExamRequestsRow,
      columns: specialExamRequestsColumns(id),
    }}
  />
)

export const SecureMaterialsRequests = ({ data = [], id, error }) => (
  <TableTemplate
    header="Additional CD Materials"
    headerStyle={{ marginTop: 30, marginBottom: 5 }}
    emptyText="You do not have any secure ancillary material requests or all requests have been hidden by the selected filter."
    error={error}
    tableAttrs={{
      id,
      caption: 'View all material adjustment requests',
      summary:
        'Materials adjustments to your order are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers.',
      data,
      rowBuilder: renderSecureMaterialsRequestsRow,
      columns: secureMaterialsColumns(id),
    }}
  />
)

export const NonSecureMaterialsRequests = connect(mapStateToProps)(
  ({ data = [], id, error, examWindowRefData }) => (
    <TableTemplate
      header="Non-Secure Ancillary Materials"
      headerStyle={{ marginTop: 30, marginBottom: 5 }}
      emptyText="You do not have any non-secure ancillary material requests or all requests have been hidden by the selected filter."
      error={error}
      tableAttrs={{
        id,
        caption: 'View all non-secure material adjustment requests',
        summary:
          'Non-secure materials adjustments to your order are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers.',
        data,
        rowBuilder: () => renderNonSecureMaterialsRequestsRow(data, examWindowRefData),
        columns: nonSecureMaterialsColumns(id),
      }}
    />
  )
)

export const ExceptionTestingRequests = ({ data = [], id, error }) => (
  <TableTemplate
    header="Exception Testing"
    headerStyle={{ marginTop: 30, marginBottom: 5 }}
    emptyText="You do not have any exception testing requests or all requests have been hidden by the selected filter."
    error={error}
    tableAttrs={{
      id,
      caption: 'View of all exception testing requests',
      summary:
        'Exception testing adjustments to your order are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers.',
      data,
      rowBuilder: renderExceptionTestingRequestsRow,
      columns: exceptionTestingColumns(id),
    }}
  />
)

export const OrdersAfterDeadlineRequests = ({ data = [], id, error }) => (
  <TableTemplate
    header="Orders After Deadline"
    headerStyle={{ marginTop: 30, marginBottom: 5 }}
    emptyText="You do not have any orders after deadline requests or all requests have been hidden by the selected filter."
    error={error}
    tableAttrs={{
      id,
      caption: 'View all orders after deadline requests',
      summary:
        'Orders after deadline adjustments to your order are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers.',
      data,
      rowBuilder: renderOrdersAfterDeadlineRequestsRow,
      columns: ordersAfterDeadlineRequestsColumns(id),
    }}
  />
)

const renderOffCycleTestingRequestsRow = data =>
  data.map(c => (
    <tr key={c.specialOrderId}>
      <th scope="row">
        <Link to={`/student/${c.studentId}`}>{c.studentName}</Link>
      </th>
      <td>{c.course}</td>
      <td className={styles['with-icon']}>
        <div className={`${styles['exam-date-wrapper']} ${styles.flexible}`}>
          <DisplayExamDate course={c} />
        </div>
      </td>
      <ApprovalStatusColumn data={c} />
    </tr>
  ))

const offCycleTestingColumns = id => [
  {
    headerText: { title: 'Student Name' },
    key: 'studentName',
    sorting: 'asc',
    headerAttributes: { id: `${id}-StudentName` },
    defaultSort: true,
  },
  {
    headerText: { title: 'Course Name' },
    key: 'course',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Course` },
  },
  {
    headerText: { title: 'Exam Date' },
    key: 'examDate',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ExamDate` },
  },
  {
    headerText: { title: 'Approval Status' },
    key: 'approvalStatusSortOrder',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ApprovalStatus` },
  },
]

export const OffCycleTestingRequests = ({ data = [], id, error }) => (
  <TableTemplate
    header="Off-Cycle Testing"
    headerStyle={{ marginTop: 30, marginBottom: 5 }}
    emptyText="You do not have any off-cycle testing requests or all requests have been hidden by the selected filter."
    error={error}
    tableAttrs={{
      id,
      caption: 'View of all off-cycle testing requests',
      summary:
        'Off-Cycle testing adjustments to your order are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers.',
      data,
      rowBuilder: renderOffCycleTestingRequestsRow,
      columns: offCycleTestingColumns(id),
    }}
  />
)
