import { isValidDefaultFormat, parse, isValidDate } from '@myap/ui-library/esm/date'
import { isEmpty } from '../../../utils/common'
import { DOMESTIC_PHONE_LENGTH } from '../../../constants/SettingsConstants'
import { convertToNumberString, validatePhoneNumberByCountryCode } from '../../../utils/numbers'

export const RequiredValidation = value =>
  value !== null && value !== undefined && !isEmpty(value) ? undefined : 'Error: Field is required.'

export const RequiredCheckboxValidation = value => (value ? undefined : 'Error: Field is required.')

export const AddRequiredValidation = (isRequired, otherTests = [], isCheckbox = false) => {
  const _validation = isCheckbox ? RequiredCheckboxValidation : RequiredValidation
  return isRequired ? [_validation, ...otherTests] : otherTests
}

export const LengthValidation = len => value =>
  !value || (value && value.length === len) ? undefined : `Error: Field must be ${len} characters.`

export const PositiveNumberValidation = (value, allValues, props, fieldName) => {
  const number = parseInt(value, 10)
  const minimum = props[`minimum${fieldName}`] ?? 1

  return value === undefined ||
    value === null ||
    value === '' ||
    (/^\d+$/.test(value) && number >= minimum)
    ? undefined
    : `Error: Field must be a number greater than or equal to ${minimum}.`
}

export const PositiveMaximumNumberValidation = (value, allValues, props, fieldName) => {
  const number = parseInt(value, 10)
  const maximum = props[`maximum${fieldName}`] ?? 100

  return value === undefined ||
    value === null ||
    value === '' ||
    (/^\d+$/.test(value) && number <= maximum)
    ? undefined
    : `Error: Field must be a number less than or equal to ${maximum}.`
}
export const USZipValidation = value =>
  !value || /^\d+$/.test(value) ? undefined : 'Error: Zip Code can be numbers only.'

export const AlphanumericValidation = value =>
  !value || /^[a-z\d\s]+$/i.test(value)
    ? undefined
    : 'Error: Field can be letters and numbers only.'

export const FormCodeValidation = value => {
  const len = value ? value.toString().length : 0
  return len >= 4 && (!value || /^[A-Za-z0-9-]+$/.test(value))
    ? undefined
    : 'Error: Form Code field can only contain letters, numbers, hyphen and be between 4 and 20 characters long.'
}

export const LetterValidation = value =>
  !value || /^[A-Za-z]+$/i.test(value) ? undefined : 'Error: Field can be letters only.'

export const NameValidation = value =>
  !value || /^(?![- '])(?!.*[- ']$)[-A-Za-z ']*$/.test(value)
    ? undefined
    : 'Error: Field may only contain letters, space, hyphen, or apostrophe.'

export const CustomFontSizeValidation = value => {
  const fontSize = parseInt(value, 10)

  return isEmpty(value) || (/^\d+$/.test(value) && fontSize > 20 && fontSize <= 100)
    ? undefined
    : 'Error: Custom font size must be greater than 20 and less than 100.'
}

export const PhoneValidation = (value, allValues, props) => {
  const countryCode = props?.countryCode ?? null
  const len = value ? value.toString().length : 0
  if (!countryCode) {
    return len === 0 || (len === DOMESTIC_PHONE_LENGTH && /^\d+$/.test(value))
      ? undefined
      : 'Error: Invalid phone number.'
  }
  return len === 0 || validatePhoneNumberByCountryCode(value, countryCode)
    ? undefined
    : 'Error: Invalid phone number.'
}

export const ConfirmEmailValidation = (value, allValues, props, fieldName) => {
  const keys = fieldName.split('.').slice(1)
  const email = keys.reduce((arr, key) => arr[key] || allValues[key], '')
  return email == value ? undefined : 'Error: Email addresses do not match.'
}
export const EmailValidation = value => {
  return isEmpty(value) ||
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(
      value
    )
    ? undefined
    : 'Error: Invalid email address.'
}

export const LimitedStringValidation = value =>
  !value || /^[-_.~:, 'A-Za-z0-9]+$/.test(value)
    ? undefined
    : 'Error: Value may only contain alpha numeric characters, space, hyphen, underscore, tilde, period, comma, apostrophe and colon.'

export const AddressValidation = value =>
  !value || /^[-.#:'@ A-Za-z0-9/]+$/.test(value)
    ? undefined
    : 'Error: Value may only contain alpha numeric characters, space, hyphen, pound, period, colon, at sign, apostrophe and slash.'

export const CityValidation = value =>
  !value || /^[-.#()/' A-Za-z0-9]+$/.test(value)
    ? undefined
    : 'Error: Value may only contain alphanumeric characters, space, hyphen, parentheses, forward slash, apostrophe, pound and period.'

export const PurchaseOrderValidation = value =>
  !value || /^[-_.~: A-Za-z0-9]+$/.test(value)
    ? undefined
    : 'Error: Value may only contain alpha numeric characters, space, hyphen, underscore, tilde, period and colon.'

export const CheckDateFormat = value => {
  const DATE_FORMAT = 'YYYY-MM-DD'
  if (!isEmpty(value) && isValidDefaultFormat(value)) {
    const parsedDate = parse(value)
    if (isValidDate(parsedDate)) {
      return undefined
    }
  }
  return `Error: Invalid date format. Must be ${DATE_FORMAT}.`
}

export const ZipValidation = value => {
  const len = value ? convertToNumberString(value).length : 0
  return !len || len === 9 || len === 5 ? undefined : 'Error: Invalid zip format.'
}

export const ApIdValidation = value => {
  const len = value ? value.length : 0
  return (len === 8 && /^[0123456789UVWXYZ]+$/.test(value)) || !value
    ? undefined
    : 'AP ID must be 8 characters and may only contain numbers and characters U-Z.'
}

export const AtLeastOneChecked = (value, allValues, props, fieldName) => {
  let anySelected = false
  const actualFieldName = fieldName.split('[')[0]
  if (Array.isArray(allValues[actualFieldName])) {
    anySelected = allValues[actualFieldName].some(opt =>
      Object.values(opt).some(val => val === true)
    )
  }
  return anySelected ? undefined : 'At least one option must be selected.'
}

export const CheckNotLessThanEnrollmentCount = (value, allValues, props) => {
  const {
    initialValues: { enrollmentCount = 0 },
  } = props
  return parseInt(value, 10) < enrollmentCount
    ? `Error: Max enrollment must be greater than or equal to current enrollment of ${enrollmentCount}.`
    : undefined
}
