import {
  reduxForm,
  isValid,
  change,
  formValueSelector,
  getFormMeta,
  SubmissionError,
  isPristine,
} from 'redux-form'
import {
  IncidentReportSubmitButton,
  IncidentReportSaveDraftButton,
  IncidentReportHybridIncidentFields,
  IncidentReportCoordinatorContactFields,
  IncidentReportExamInformationFields,
  IncidentReportStudentsInvolvedFields,
  IncidentReportAdditionalInformationFields,
  IncidentReportIncidentDetailFields,
} from '.'
import { Error } from '../common'
import { getSelectedEducationPeriod } from '../../selectors/settings'
import { getAllExamWindows } from '../../selectors/examWindows'
import { submitIncidentReport } from '../../actions/incidentReports'
import { openModal } from '../../actions/app'
import { isEmpty } from '../../utils/common'
import { HYBRID_EXAM } from '../../constants/SettingsConstants'
import {
  INCIDENT_REPORT_FORM_NAME,
  SHARED_FIELDS_LIST,
  HYBRID_FIELDS_LIST,
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
  INCIDENT_REPORT_PAPER_FIELD_NAMES as PAPER_FIELDS,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES as DIGITAL_FIELDS,
  NOT_ALLOWED_TO_SUBMIT,
} from '../../constants/IncidentReportConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    incidentReport: { submitting, draft },
    availableCourses: { error: errorCourses },
  } = state

  const {
    settingsOrg: {
      [orgId]: {
        data: {
          schoolInformation: {
            address: { countryCode },
          },
        },
      },
    },
  } = state

  const selector = formValueSelector(INCIDENT_REPORT_FORM_NAME)
  const sharedValues = selector(state, ...SHARED_FIELDS_LIST)
  const isDraft = selector(state, 'isDraft')
  const incidentTypeValues = selector(state, ...HYBRID_FIELDS_LIST)
  const currentValues = {
    ...sharedValues,
    ...incidentTypeValues,
    isDraft,
  }

  const saveDraftDisabled =
    submitting ||
    isPristine(INCIDENT_REPORT_FORM_NAME)(state) ||
    isEmpty(currentValues[SHARED_FIELDS.firstName.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.lastName.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.phone.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.email.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.section.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.testCode.name]) ||
    isEmpty(currentValues[SHARED_FIELDS.examDate.name])

  return {
    orgId,
    educationPeriodCd: getSelectedEducationPeriod(state),
    countryCode,
    saveDraftDisabled,
    draft,
    currentValues,
    errorCourses,
    allExamWindows: getAllExamWindows(state),
    valid: isValid(INCIDENT_REPORT_FORM_NAME)(state),
    formFieldsMeta: getFormMeta(INCIDENT_REPORT_FORM_NAME)(state),
  }
}

function CreateIncidentReportHybridForm({
  orgId,
  educationPeriodCd,
  countryCode,
  saveDraftDisabled,
  draft,
  currentValues,
  error,
  errorCourses,
  valid,
  submitting,
  allExamWindows,
  notAllowedToSubmit,
  setSelectedTestCd,
  setSelectedExamDate,
  handleSubmit,
  change,
}) {
  const isDraft = currentValues.isDraft ?? false

  const legendStyles = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    border: 'none',
    marginBottom: '16px',
    lineHeight: '24px',
  }

  const onClickAddStudent = () => {
    if (
      !isEmpty(currentValues[SHARED_FIELDS.studentFirstName.name]) &&
      !isEmpty(currentValues[SHARED_FIELDS.studentLastName.name]) &&
      !isEmpty(currentValues[SHARED_FIELDS.studentApId.name])
    ) {
      change(SHARED_FIELDS.students.name, [
        ...currentValues[SHARED_FIELDS.students.name],
        {
          [SHARED_FIELDS.firstName.name]: currentValues[SHARED_FIELDS.studentFirstName.name],
          [SHARED_FIELDS.lastName.name]: currentValues[SHARED_FIELDS.studentLastName.name],
          [SHARED_FIELDS.studentApId.name]: currentValues[SHARED_FIELDS.studentApId.name],
        },
      ])
      change(SHARED_FIELDS.studentFirstName.name, '')
      change(SHARED_FIELDS.studentLastName.name, '')
      change(SHARED_FIELDS.studentApId.name, '')
    }
  }

  const onClickRemoveStudent = e => {
    const apId = e.target.id.split('_')[1]
    const remainingStudents = currentValues?.[SHARED_FIELDS.students.name]?.filter(
      student => student[SHARED_FIELDS.studentApId.name] !== apId
    )
    change(SHARED_FIELDS.students.name, remainingStudents)
  }

  return (
    <form onSubmit={handleSubmit}>
      <IncidentReportCoordinatorContactFields countryCode={countryCode} />
      <IncidentReportExamInformationFields
        currentValues={currentValues}
        draft={draft}
        orgId={orgId}
        educationPeriodCd={educationPeriodCd}
        examFormat={HYBRID_EXAM}
        allExamWindows={allExamWindows}
        setSelectedTestCd={setSelectedTestCd}
        setSelectedExamDate={setSelectedExamDate}
      />
      <div className="row">
        <div className="col-xs-12">
          <h3 className="cb-required">Type of Incident</h3>
          <fieldset>
            <legend style={legendStyles}>
              Select all that apply, you must select at-least one from the given options.
            </legend>
            <IncidentReportHybridIncidentFields currentValues={currentValues} />
          </fieldset>
        </div>
      </div>
      <IncidentReportAdditionalInformationFields />
      <IncidentReportStudentsInvolvedFields
        currentValues={currentValues}
        addStudent={onClickAddStudent}
        removeStudent={onClickRemoveStudent}
      />
      <IncidentReportIncidentDetailFields
        isPaper={false}
        isDraft={isDraft}
        currentValues={currentValues}
      />
      {error || errorCourses ? (
        <div className="row">
          <div className="col-xs-12">
            {error ? (
              <p
                className="cb-error-msg"
                role="alert"
                style={{ marginTop: '24px' }}
                aria-live="polite"
              >
                {error}
              </p>
            ) : null}
            {errorCourses ? <Error message={errorCourses} style={{ marginTop: '24px' }} /> : null}
          </div>
        </div>
      ) : null}
      {valid && notAllowedToSubmit ? (
        <p className="cb-error-msg" role="alert" style={{ marginTop: '24px' }} aria-live="polite">
          {NOT_ALLOWED_TO_SUBMIT}
        </p>
      ) : null}
      <div className="row">
        <div className="col-xs-12">
          <IncidentReportSubmitButton
            disabled={!valid || notAllowedToSubmit || submitting}
            addStudent={onClickAddStudent}
          />
          <IncidentReportSaveDraftButton
            disabled={saveDraftDisabled || notAllowedToSubmit}
            submitting={submitting}
            isDraft={isDraft}
            setDraft={change}
            addStudent={onClickAddStudent}
          />
        </div>
      </div>
    </form>
  )
}

export default connect(mapStateToProps, { change, openModal, submitIncidentReport })(
  reduxForm({
    form: INCIDENT_REPORT_FORM_NAME,
    enableReinitialize: true,
    updateUnregisteredFields: true,
    keepDirtyOnReinitialize: true,
    validate: (vals, { formFieldsMeta }) => {
      const isDraft = vals.isDraft ?? false
      const errors = {}

      if (
        !isDraft &&
        vals[PAPER_FIELDS.defectiveMatsInd.name] &&
        !vals[PAPER_FIELDS.multipleChoiceInd.name] &&
        !vals[PAPER_FIELDS.freeResponseInd.name] &&
        !vals[PAPER_FIELDS.shortAnswerResponseInd.name] &&
        !vals[PAPER_FIELDS.orangeBookletInd.name] &&
        !vals[PAPER_FIELDS.masterAudioCDs.name]
      ) {
        errors[PAPER_FIELDS.defectiveMatsInd.name] =
          'Error: At least one of the following options must be selected'
      }

      if (
        !isDraft &&
        (formFieldsMeta[PAPER_FIELDS.defectiveMatsInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.multipleChoiceInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.freeResponseInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.orangeBookletInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.masterAudioCDsInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.masterAudioCDs.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.earlyOpeningSectionInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.batteryDiedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.connectionDroppedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.loanerDeviceIssueInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.anotherTestAppInstalledInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.blueBookNotInstalledInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.examSetUpNotCompletedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.noAdmissionTicketInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.studentTechIssueOther.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.couldNotOpenInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.couldNotSignInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.testNotAvailableInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.inAccurateTimingInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.contentDisplayIssueInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.answerNotSubmittedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.blueBookProblemOther.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.staffBatteryDiedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.staffConnectionDroppedInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.staffDeviceIssueInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.staffTechIssueOther.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.couldNotAccessTDTKInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.studentCheckInIssueInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.inAccurateStudentStatusInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.testDayToolKitProblemOther.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.networkOutageInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.powerOutageInd.name]?.visited ||
          formFieldsMeta[DIGITAL_FIELDS.facilityTechIssueOther.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.misconductInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.illnessInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.misplacedAnswersInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.missingExamMaterialsInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.mixUpFreeResponseBooksInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.sharedSameAPIDInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.wrngColorInkInd.name]?.visited ||
          formFieldsMeta[PAPER_FIELDS.usedExtraPaperInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.interruptionMinutesInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.disturbanceMinutesInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.otherInd.name]?.visited) &&
        !vals[PAPER_FIELDS.defectiveMatsInd.name] &&
        !vals[PAPER_FIELDS.multipleChoiceInd.name] &&
        !vals[PAPER_FIELDS.freeResponseInd.name] &&
        !vals[PAPER_FIELDS.orangeBookletInd.name] &&
        !vals[PAPER_FIELDS.masterAudioCDsInd.name] &&
        !vals[PAPER_FIELDS.masterAudioCDs.name] &&
        !vals[PAPER_FIELDS.earlyOpeningSectionInd.name] &&
        !vals[PAPER_FIELDS.wrngColorInkInd.name] &&
        !vals[PAPER_FIELDS.usedExtraPaperInd.name] &&
        !vals[PAPER_FIELDS.sharedSameAPIDInd.name] &&
        !vals[PAPER_FIELDS.mixUpFreeResponseBooksInd.name] &&
        !vals[PAPER_FIELDS.misplacedAnswersInd.name] &&
        !vals[PAPER_FIELDS.missingExamMaterialsInd.name] &&
        !vals[DIGITAL_FIELDS.batteryDiedInd.name] &&
        !vals[DIGITAL_FIELDS.connectionDroppedInd.name] &&
        !vals[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name] &&
        !vals[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name] &&
        !vals[DIGITAL_FIELDS.loanerDeviceIssueInd.name] &&
        !vals[DIGITAL_FIELDS.anotherTestAppInstalledInd.name] &&
        !vals[DIGITAL_FIELDS.blueBookNotInstalledInd.name] &&
        !vals[DIGITAL_FIELDS.examSetUpNotCompletedInd.name] &&
        !vals[DIGITAL_FIELDS.noAdmissionTicketInd.name] &&
        !vals[DIGITAL_FIELDS.studentTechIssueOther.name] &&
        !vals[DIGITAL_FIELDS.couldNotOpenInd.name] &&
        !vals[DIGITAL_FIELDS.couldNotSignInd.name] &&
        !vals[DIGITAL_FIELDS.testNotAvailableInd.name] &&
        !vals[DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateTimingInd.name] &&
        !vals[DIGITAL_FIELDS.contentDisplayIssueInd.name] &&
        !vals[DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name] &&
        !vals[DIGITAL_FIELDS.answerNotSubmittedInd.name] &&
        !vals[DIGITAL_FIELDS.blueBookProblemOther.name] &&
        !vals[DIGITAL_FIELDS.staffBatteryDiedInd.name] &&
        !vals[DIGITAL_FIELDS.staffConnectionDroppedInd.name] &&
        !vals[DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.name] &&
        !vals[DIGITAL_FIELDS.staffDeviceIssueInd.name] &&
        !vals[DIGITAL_FIELDS.staffTechIssueOther.name] &&
        !vals[DIGITAL_FIELDS.couldNotAccessTDTKInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name] &&
        !vals[DIGITAL_FIELDS.studentCheckInIssueInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateStudentStatusInd.name] &&
        !vals[DIGITAL_FIELDS.testDayToolKitProblemOther.name] &&
        !vals[DIGITAL_FIELDS.networkOutageInd.name] &&
        !vals[DIGITAL_FIELDS.powerOutageInd.name] &&
        !vals[DIGITAL_FIELDS.facilityTechIssueOther.name] &&
        !vals[SHARED_FIELDS.misconductInd.name] &&
        !vals[SHARED_FIELDS.illnessInd.name] &&
        !vals[SHARED_FIELDS.disturbanceMinutesInd.name] &&
        !vals[SHARED_FIELDS.interruptionMinutesInd.name] &&
        !vals[SHARED_FIELDS.otherInd.name]
      ) {
        errors._error = 'Error: At least one type of incident must be selected'
      }

      if (
        !isDraft &&
        (formFieldsMeta[SHARED_FIELDS.fullTestCenterImpactedInd.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.studentFirstName.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.studentLastName.name]?.visited ||
          formFieldsMeta[SHARED_FIELDS.studentApId.name]?.visited) &&
        vals[SHARED_FIELDS.students.name]?.length === 0 &&
        (isEmpty(vals[SHARED_FIELDS.studentFirstName.name]) ||
          isEmpty(vals[SHARED_FIELDS.studentLastName.name]) ||
          isEmpty(vals[SHARED_FIELDS.studentApId.name])) &&
        !vals[SHARED_FIELDS.fullTestCenterImpactedInd.name]
      ) {
        errors._error =
          'Error: Must either select that all students were impacted or at least one student involved must be listed.'
      }

      return errors
    },
    onSubmit: async (
      vals,
      dispatch,
      { orgId, educationPeriodCd, submitIncidentReport, openModal }
    ) => {
      const isDraft = vals.isDraft ?? false

      if (
        vals[PAPER_FIELDS.defectiveMatsInd.name] &&
        !vals[PAPER_FIELDS.multipleChoiceInd.name] &&
        !vals[PAPER_FIELDS.freeResponseInd.name] &&
        !vals[PAPER_FIELDS.shortAnswerResponseInd.name] &&
        !vals[PAPER_FIELDS.orangeBookletInd.name] &&
        !vals[PAPER_FIELDS.masterAudioCDs.name]
      ) {
        throw new SubmissionError({
          [PAPER_FIELDS.defectiveMatsInd.name]:
            'Error: At least one of the following options must be selected',
        })
      }

      if (
        !isDraft &&
        !vals[PAPER_FIELDS.defectiveMatsInd.name] &&
        !vals[PAPER_FIELDS.multipleChoiceInd.name] &&
        !vals[PAPER_FIELDS.freeResponseInd.name] &&
        !vals[PAPER_FIELDS.orangeBookletInd.name] &&
        !vals[PAPER_FIELDS.masterAudioCDsInd.name] &&
        !vals[PAPER_FIELDS.masterAudioCDs.name] &&
        !vals[PAPER_FIELDS.earlyOpeningSectionInd.name] &&
        !vals[PAPER_FIELDS.wrngColorInkInd.name] &&
        !vals[PAPER_FIELDS.usedExtraPaperInd.name] &&
        !vals[PAPER_FIELDS.sharedSameAPIDInd.name] &&
        !vals[PAPER_FIELDS.mixUpFreeResponseBooksInd.name] &&
        !vals[PAPER_FIELDS.misplacedAnswersInd.name] &&
        !vals[PAPER_FIELDS.missingExamMaterialsInd.name] &&
        !vals[DIGITAL_FIELDS.batteryDiedInd.name] &&
        !vals[DIGITAL_FIELDS.connectionDroppedInd.name] &&
        !vals[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name] &&
        !vals[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name] &&
        !vals[DIGITAL_FIELDS.loanerDeviceIssueInd.name] &&
        !vals[DIGITAL_FIELDS.anotherTestAppInstalledInd.name] &&
        !vals[DIGITAL_FIELDS.blueBookNotInstalledInd.name] &&
        !vals[DIGITAL_FIELDS.examSetUpNotCompletedInd.name] &&
        !vals[DIGITAL_FIELDS.noAdmissionTicketInd.name] &&
        !vals[DIGITAL_FIELDS.studentTechIssueOther.name] &&
        !vals[DIGITAL_FIELDS.couldNotOpenInd.name] &&
        !vals[DIGITAL_FIELDS.couldNotSignInd.name] &&
        !vals[DIGITAL_FIELDS.testNotAvailableInd.name] &&
        !vals[DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateTimingInd.name] &&
        !vals[DIGITAL_FIELDS.contentDisplayIssueInd.name] &&
        !vals[DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name] &&
        !vals[DIGITAL_FIELDS.answerNotSubmittedInd.name] &&
        !vals[DIGITAL_FIELDS.blueBookProblemOther.name] &&
        !vals[DIGITAL_FIELDS.staffBatteryDiedInd.name] &&
        !vals[DIGITAL_FIELDS.staffConnectionDroppedInd.name] &&
        !vals[DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.name] &&
        !vals[DIGITAL_FIELDS.staffDeviceIssueInd.name] &&
        !vals[DIGITAL_FIELDS.staffTechIssueOther.name] &&
        !vals[DIGITAL_FIELDS.couldNotAccessTDTKInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name] &&
        !vals[DIGITAL_FIELDS.studentCheckInIssueInd.name] &&
        !vals[DIGITAL_FIELDS.inAccurateStudentStatusInd.name] &&
        !vals[DIGITAL_FIELDS.testDayToolKitProblemOther.name] &&
        !vals[DIGITAL_FIELDS.networkOutageInd.name] &&
        !vals[DIGITAL_FIELDS.powerOutageInd.name] &&
        !vals[DIGITAL_FIELDS.facilityTechIssueOther.name] &&
        !vals[SHARED_FIELDS.misconductInd.name] &&
        !vals[SHARED_FIELDS.illnessInd.name] &&
        !vals[SHARED_FIELDS.disturbanceMinutesInd.name] &&
        !vals[SHARED_FIELDS.interruptionMinutesInd.name] &&
        !vals[SHARED_FIELDS.otherInd.name]
      ) {
        throw new SubmissionError({
          _error: 'Error: At least one type of incident must be selected',
        })
      }

      if (
        !isDraft &&
        vals[SHARED_FIELDS.students.name]?.length === 0 &&
        (isEmpty(vals[SHARED_FIELDS.studentFirstName.name]) ||
          isEmpty(vals[SHARED_FIELDS.studentLastName.name]) ||
          isEmpty(vals[SHARED_FIELDS.studentApId.name])) &&
        !vals[SHARED_FIELDS.fullTestCenterImpactedInd.name]
      ) {
        throw new SubmissionError({
          _error:
            'Error: Must either select that all students were impacted or at least one student involved must be listed.',
        })
      }

      const submitValues = {
        coordinator: {
          [SHARED_FIELDS.firstName.name]: vals[SHARED_FIELDS.firstName.name],
          [SHARED_FIELDS.lastName.name]: vals[SHARED_FIELDS.lastName.name],
          [SHARED_FIELDS.phone.name]: vals[SHARED_FIELDS.phone.name],
          [SHARED_FIELDS.email.name]: vals[SHARED_FIELDS.email.name],
        },
        exam: {
          [SHARED_FIELDS.testCode.name]: parseInt(vals[SHARED_FIELDS.testCode.name], 10),
          [SHARED_FIELDS.section.name]: vals[SHARED_FIELDS.section.name],
          [SHARED_FIELDS.examDate.name]: vals[SHARED_FIELDS.examDate.name],
          examFormat: HYBRID_EXAM,
        },
        incidentType: {
          [SHARED_FIELDS.interruptionMinutes.name]: vals[SHARED_FIELDS.interruptionMinutesInd.name]
            ? parseInt(vals[SHARED_FIELDS.interruptionMinutes.name], 10)
            : null,
          [SHARED_FIELDS.disturbanceMinutes.name]: vals[SHARED_FIELDS.disturbanceMinutesInd.name]
            ? parseInt(vals[SHARED_FIELDS.disturbanceMinutes.name], 10)
            : null,
          [SHARED_FIELDS.illnessInd.name]: vals[SHARED_FIELDS.illnessInd.name],
          [PAPER_FIELDS.earlyOpeningSectionInd.name]:
            vals[PAPER_FIELDS.earlyOpeningSectionInd.name],
          [PAPER_FIELDS.mixUpFreeResponseBooksInd.name]:
            vals[PAPER_FIELDS.mixUpFreeResponseBooksInd.name],
          [PAPER_FIELDS.sharedSameAPIDInd.name]: vals[PAPER_FIELDS.sharedSameAPIDInd.name],
          [PAPER_FIELDS.wrngColorInkInd.name]: vals[PAPER_FIELDS.wrngColorInkInd.name],
          [PAPER_FIELDS.usedExtraPaperInd.name]: vals[PAPER_FIELDS.usedExtraPaperInd.name],
          [PAPER_FIELDS.misplacedAnswersInd.name]: vals[PAPER_FIELDS.misplacedAnswersInd.name],
          [PAPER_FIELDS.missingExamMaterialsInd.name]:
            vals[PAPER_FIELDS.missingExamMaterialsInd.name],
          [SHARED_FIELDS.otherInd.name]: vals[SHARED_FIELDS.otherInd.name],
          defectiveMaterials: {
            [PAPER_FIELDS.multipleChoiceInd.name]: vals[PAPER_FIELDS.multipleChoiceInd.name],
            [PAPER_FIELDS.freeResponseInd.name]: vals[PAPER_FIELDS.freeResponseInd.name],
            [PAPER_FIELDS.orangeBookletInd.name]: vals[PAPER_FIELDS.orangeBookletInd.name],
            [PAPER_FIELDS.masterAudioCDs.name]: vals[PAPER_FIELDS.masterAudioCDsInd.name]
              ? vals[PAPER_FIELDS.masterAudioCDs.name]
              : null,
          },
          studentTechIssue: {
            [DIGITAL_FIELDS.batteryDiedInd.name]: vals[DIGITAL_FIELDS.batteryDiedInd.name],
            [DIGITAL_FIELDS.connectionDroppedInd.name]:
              vals[DIGITAL_FIELDS.connectionDroppedInd.name],
            [DIGITAL_FIELDS.deviceFrozeOrCrashInd.name]:
              vals[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name],
            [DIGITAL_FIELDS.deviceDidNotMeetReqInd.name]:
              vals[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name],
            [DIGITAL_FIELDS.loanerDeviceIssueInd.name]:
              vals[DIGITAL_FIELDS.loanerDeviceIssueInd.name],
            [DIGITAL_FIELDS.anotherTestAppInstalledInd.name]:
              vals[DIGITAL_FIELDS.anotherTestAppInstalledInd.name],
            [DIGITAL_FIELDS.blueBookNotInstalledInd.name]:
              vals[DIGITAL_FIELDS.blueBookNotInstalledInd.name],
            [DIGITAL_FIELDS.examSetUpNotCompletedInd.name]:
              vals[DIGITAL_FIELDS.examSetUpNotCompletedInd.name],
            [DIGITAL_FIELDS.noAdmissionTicketInd.name]:
              vals[DIGITAL_FIELDS.noAdmissionTicketInd.name],
            otherInd: vals[DIGITAL_FIELDS.studentTechIssueOther.name],
          },
          blueBookProblem: {
            [DIGITAL_FIELDS.couldNotOpenInd.name]: vals[DIGITAL_FIELDS.couldNotOpenInd.name],
            [DIGITAL_FIELDS.couldNotSignInd.name]: vals[DIGITAL_FIELDS.couldNotSignInd.name],
            [DIGITAL_FIELDS.testNotAvailableInd.name]:
              vals[DIGITAL_FIELDS.testNotAvailableInd.name],
            [DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name]:
              vals[DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name],
            [DIGITAL_FIELDS.inAccurateTimingInd.name]:
              vals[DIGITAL_FIELDS.inAccurateTimingInd.name],
            [DIGITAL_FIELDS.contentDisplayIssueInd.name]:
              vals[DIGITAL_FIELDS.contentDisplayIssueInd.name],
            [DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name]:
              vals[DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name],
            [DIGITAL_FIELDS.answerNotSubmittedInd.name]:
              vals[DIGITAL_FIELDS.answerNotSubmittedInd.name],
            otherInd: vals[DIGITAL_FIELDS.blueBookProblemOther.name],
          },
          staffTechIssue: {
            [DIGITAL_FIELDS.staffBatteryDiedInd.propName]:
              vals[DIGITAL_FIELDS.staffBatteryDiedInd.name],
            [DIGITAL_FIELDS.staffConnectionDroppedInd.propName]:
              vals[DIGITAL_FIELDS.staffConnectionDroppedInd.name],
            [DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.propName]:
              vals[DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.name],
            [DIGITAL_FIELDS.staffDeviceIssueInd.propName]:
              vals[DIGITAL_FIELDS.staffDeviceIssueInd.name],
            [DIGITAL_FIELDS.staffTechIssueOther.propName]:
              vals[DIGITAL_FIELDS.staffTechIssueOther.name],
          },
          facilityTechIssue: {
            [DIGITAL_FIELDS.networkOutageInd.name]: vals[DIGITAL_FIELDS.networkOutageInd.name],
            [DIGITAL_FIELDS.powerOutageInd.name]: vals[DIGITAL_FIELDS.powerOutageInd.name],
            otherInd: vals[DIGITAL_FIELDS.facilityTechIssueOther.name],
          },
          testDayToolKitProblem: {
            [DIGITAL_FIELDS.couldNotAccessTDTKInd.name]:
              vals[DIGITAL_FIELDS.couldNotAccessTDTKInd.name],
            [DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name]:
              vals[DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name],
            [DIGITAL_FIELDS.studentCheckInIssueInd.name]:
              vals[DIGITAL_FIELDS.studentCheckInIssueInd.name],
            [DIGITAL_FIELDS.inAccurateStudentStatusInd.name]:
              vals[DIGITAL_FIELDS.inAccurateStudentStatusInd.name],
            otherInd: vals[DIGITAL_FIELDS.testDayToolKitProblemOther.name],
          },
          misconduct: {
            [SHARED_FIELDS.misconductInd.name]: vals[SHARED_FIELDS.misconductInd.name],
            [SHARED_FIELDS.studentDismissedInd.name]: vals[SHARED_FIELDS.studentDismissedInd.name],
            type: {
              [SHARED_FIELDS.usedProhibitedDeviceInd.name]:
                vals[SHARED_FIELDS.usedProhibitedDeviceInd.name],
              [SHARED_FIELDS.removeExamMaterialsInd.name]:
                vals[SHARED_FIELDS.removeExamMaterialsInd.name],
            },
          },
        },
        [SHARED_FIELDS.lateTestingRequiredInd.name]:
          vals[SHARED_FIELDS.lateTestingRequiredInd.name],
        [SHARED_FIELDS.altExamOrderedInd.name]: vals[SHARED_FIELDS.altExamOrderedInd.name],
        [SHARED_FIELDS.fullTestCenterImpactedInd.name]:
          vals[SHARED_FIELDS.fullTestCenterImpactedInd.name],
        [SHARED_FIELDS.students.name]: vals[SHARED_FIELDS.students.name],
        [SHARED_FIELDS.incidentDetail.name]: vals[SHARED_FIELDS.incidentDetail.name],
      }
      await submitIncidentReport({ orgId, educationPeriodCd, values: { ...submitValues, isDraft } })
      if (isDraft) {
        openModal('SavedIncidentReportDraftModal')
      }
    },
    onSubmitFail: (errors, dispatch, submitError, { change }) => {
      // console.error('*** submitError:', submitError)
      change('isDraft', false)
      // throw new SubmissionError({
      //   _error: 'An error occurred while submitting this Incident Report.',
      // })
    },
  })(CreateIncidentReportHybridForm)
)
